import { WebFontConfig } from './_web_fonts';
window.WebFontConfig = WebFontConfig; // Expose .jshints global variable for webfont loader JS

import { setup_main_nav } from './deep-fried-modules/_main_nav';
// import { setup_back_to_top } from './deep-fried-modules/_back_to_top';
import { setup_retina_assets } from './deep-fried-modules/_retina';
import { setup_acf_google_maps } from './deep-fried-modules/_acf_google_maps';
import { setup_sticky_nav } from './_sticky_nav';
import { setup_slider } from './_home-slider';
import setup_snackbar from './_snackbar';

jQuery( document ).ready( ( $ ) =>
{
	setup_main_nav();
	// setup_back_to_top();
	setup_retina_assets();
	setup_acf_google_maps();
	setup_sticky_nav();
	setup_slider();
	setup_snackbar();
} );

/*
 * Name        : BX SLider
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Enables Homepage slideshow
*/
import { $, $html, $menu_button } from './_var';



const $slider = $( '.bxslider' );
const setup_slider = () =>
{
	if( $('body').hasClass('home') )
	{
		$slider.bxSlider(
			{
				mode     : 'fade',
				speed    : 1000,
				pause    : 5500,
				captions : false,
				auto     : true,
				controls : false,
				pager    : false,
			} );
	}
};


export { setup_slider };

/*
 * Name        : Snackbar
 * Version     : 0.1.1
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfried.com
 * Description : Adds local cookie and manages snackbar visibility
*/

import { waitForFinalEvent } from './deep-fried-modules/_final_event';

export default () => {
	const html          = document.querySelector('html');
	const body          = document.querySelector('body');
	const snackBar      = document.querySelector('.df-snackbar');
	const closeSnackBar = document.querySelector('.df-snackbar__close');
	const cookieID      = (snackBar) ? snackBar.dataset.cookie : '';

	function setSnackbarCookie() {
		localStorage.setItem('snackbar', cookieID);
	}

	function hideSnackbar() {
		html.classList.remove('df-snackbar--is-visible');
		body.style.paddingTop = 0;
	}

	function snackbarPaddingOffset() {
		const snackbarHeight  = snackBar.offsetHeight;
		body.style.paddingTop = `${snackbarHeight}px`;
	}
	
	// Check browser support
	if ('undefined' !== typeof (Storage)) {
		if (cookieID === localStorage.getItem('snackbar')) {
			html.classList.remove('df-snackbar--is-visible');
		} else {
			html.classList.add('df-snackbar--is-visible');
		}

		if (snackBar) {
			snackbarPaddingOffset();
			closeSnackBar.addEventListener('click', hideSnackbar);
			closeSnackBar.addEventListener('click', setSnackbarCookie);
			window.addEventListener('resize', snackbarPaddingOffset);
		}
	}
};

/*
 * Name        : DFA Main Nav Module
 * Version     : 0.3.1
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Adds click event listeners to `.main-nav a`
*/

import { $, $html, $menu_button } from './_var';

const $main_nav = $( '.main-nav' );
const $sticky		= 'nav-top';
const $window		= $(window);
const $screen_height = $window.height();

const setup_sticky_nav = () =>
{

	$window.scroll( () =>
	{
		var $head_height = $window.scrollTop();
		var $quarter_screen = $screen_height / 8;

		if ( $head_height < $quarter_screen )
			{
				$main_nav.addClass( $sticky );
			}
		else
			{
				$main_nav.removeClass( $sticky );
			}
		});
};

export { setup_sticky_nav };
